import React from 'react';
import { graphql } from 'gatsby';
//
import { NewsPage } from 'arvesta-ui-library';
import { NewsListingModuleProps } from 'arvesta-ui-library/build/components/Modules/NewsListingModule';
import {
  ContentfulNews,
  ContentfulSettings,
  ContentfulNewsListingSection,
  ContentfulNewsConnection,
} from '../generated/gatsby.types';
import Layout from '../components/layout';
import extractShortDescription, { ItemType } from '../transforms/ShortDescription';
import transformNewsListingSection from '../transforms/NewsListing';
import transformProductsServicesSection from '../transforms/ProductsServices';
import extractFileInforamtion from '../transforms/File';

type NewsQueryType = {
  contentfulNews: ContentfulNews;
  contentfulCookieBanner: any;
  site: any;
  contentfulSettings: ContentfulSettings;
  contentfulNewsListingSection: ContentfulNewsListingSection;
  allContentfulNews: ContentfulNewsConnection;
};

type PageContextType = {
  id: number;
  node_locale: string;
};

type NewsProps = {
  data: NewsQueryType;
  pageContext: PageContextType;
};

const News = ({ data, pageContext }: NewsProps) => {
  const {
    title,
    date,
    shortDescription,
    contentsSection,
    image,
    featuredProducts,
    document,
    updatedAt,
  } = data.contentfulNews;

  return (
    <Layout
      pageTitle={title as string}
      pageDescription={extractShortDescription(shortDescription as any)}
      pageImage={image || undefined}
      pageLocale={pageContext.node_locale}
      pageType="news"
      siteUrl={data.site.siteMetadata.siteUrl}
      pageSlug={data.contentfulNews.slug ? data.contentfulNews.slug : ''}
      contentfulSettings={data.contentfulSettings}
      pageSettings={data.contentfulNews.metaTags}
      cookieBannerTitle={data.contentfulCookieBanner.title}
      cookieBannerDescription={data.contentfulCookieBanner.description}
      updatedAt={updatedAt}
      swiftTypeDate={data.contentfulNews.date ? data.contentfulNews?.date : undefined}
      ogPageType="article"
      showFooterSubscribeForm={data.contentfulSettings.footerShowSubscribeForm || false}
      subscribeFormHeading={data.contentfulSettings.footerSubscribeFormHeading?.footerSubscribeFormHeading}
      flexmailId={data.contentfulSettings.flexmailId}
    >
      <NewsPage
        title={title as string}
        shortDescription={shortDescription ? extractShortDescription(shortDescription as any) : undefined}
        date={date}
        contentsSection={{ data: contentsSection ? contentsSection.json : null }}
        image={image}
        newsSection={{
          ...(transformNewsListingSection(
            data.contentfulNewsListingSection as ItemType,
            data.allContentfulNews,
          ) as NewsListingModuleProps),
          initialAmount: 2,
          incrementAmount: 6,
          variant: 'secondary',
        }}
        featuredProducts={featuredProducts ? transformProductsServicesSection(featuredProducts) : null}
        documentDownload={extractFileInforamtion(document)}
      />
    </Layout>
  );
};

export default News;

export const pageQuery = graphql`
  query($id: String!, $node_locale: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulCookieBanner(node_locale: { eq: $node_locale }) {
      title
      description {
        json
      }
    }
    contentfulNews(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      title
      slug
      updatedAt(formatString: "YYYYMMDDHHmmss")
      metaTags {
        id
        description
        nofollow
        title
        keywords
        canonical
        noindex
      }
      image {
        title
        alt: altText
        asset {
          fluid(quality: 100) {
            ...QueryImageFluid
          }
        }
        socialImage: asset {
          fixed(width: 1200, height: 1200, resizingBehavior: CROP, quality: 100) {
            ...QueryImageFixed
          }
        }
      }
      date(formatString: "D, MMMM Y")
      shortDescription {
        text: shortDescription
      }
      contentsSection {
        json
      }
      document {
        title
        asset {
          description
          file {
            contentType
            url
            details {
              size
            }
            fileName
          }
        }
      }
      featuredProducts {
        title
        shortDescription {
          text: shortDescription
        }
        products {
          slug
          title
          shortDescription {
            text: shortDescription
          }
          price
          image {
            asset {
              fluid {
                ...QueryImageFluid
              }
            }
          }
          id
        }
        cta {
          externalUrl
          internalEntry {
            ...FragmentNavigationLink
          }
          title
        }
      }
    }

    contentfulSettings(node_locale: { eq: $node_locale }) {
      ...QuerySiteSettings
    }

    contentfulNewsListingSection(node_locale: { eq: $node_locale }) {
      ...QueryNewsListing
    }

    allContentfulNews(filter: { node_locale: { eq: $node_locale }, id: { ne: $id } }) {
      edges {
        node {
          updatedAt
          title
          slug
          id
          date
          shortDescription {
            text: shortDescription
          }
          image {
            title
            alt: altText
            asset {
              fluid(resizingBehavior: FILL, quality: 85) {
                ...QueryImageFluid
              }
            }
          }
        }
      }
    }
  }
`;
