import { ContentfulNewsConnection } from '../generated/gatsby.types';
import extractShortDescription, { ItemType } from './ShortDescription';
import transformNewsItems from './NewsItems';

const transformNewsListingSection = (item: ItemType, newsItems: ContentfulNewsConnection, variant = 'primary') => {
  return {
    __typename: 'NewsListingSection',
    key: item.id,
    title: item.title,
    shortDescription: extractShortDescription(item),
    news: transformNewsItems(newsItems.edges),
    initialAmount: 5,
    variant,
  };
};

export default transformNewsListingSection;
